import {HowItWorksFieldsFragment, HowItWorksSectionFieldsFragment} from '../../../../__generated__/datocms.types'
import {ActiveTab, HowItWorksItem, HowItWorksSection} from '../types/how-it-works-section.types'
import {parseCallToAction} from './call-to-action.serde'

export function parseHowItWorksSection(section: HowItWorksSectionFieldsFragment): HowItWorksSection {
  return {
    __type: 'HowItWorksSection',
    plot: section.plot,
    title: section.title,
    description: section.description,
    actionButtonText: section.actionButtonText,
    howItWorksList: parseHowItWorksItemCollection(section.items),
    contactUsList: parseHowItWorksItemCollection(section.contactUsList),
    strengthsList: parseHowItWorksItemCollection(section.strengthList),
    activeTab: (section.activeTab as ActiveTab) || 'Strengths',
  }
}

function parseHowItWorksItemCollection(items: HowItWorksFieldsFragment[]): HowItWorksItem[] {
  return (
    items
      .filter(item => (item as HowItWorksFieldsFragment).__typename === 'HowItWorksItemModelRecord')
      .map(item => parseHowItWorksItem(item as HowItWorksFieldsFragment)) ?? []
  )
}

function parseHowItWorksItem(item: HowItWorksFieldsFragment): HowItWorksItem {
  return {
    id: item.id,
    title: item.title!,
    description: item.description,
    icon: {
      name: item.howItWorksIcon!,
      collection: item.collection!,
    },
    callToAction: item.callToAction ? parseCallToAction(item.callToAction) : undefined,
  }
}
